import styled from 'styled-components'
import { gray, orange } from '../../../styles/colors'

import breakpoints from '../../../styles/breakpoints'

import BackgroundSM from '../../../images/banners/background-hero-home-inter-asset-sm.png'
import BackgroundMD from '../../../images/banners/background-hero-home-inter-asset-md.png'
import BackgroundLG from '../../../images/banners/background-hero-home-inter-asset-lg.png'
import BackgroundXL from '../../../images/banners/background-hero-home-inter-asset-xl.png'

export const HeroSection = styled.section`
  display: flex;
  align-items: flex-end;
  min-height: 440px;
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;

  @media (min-width: ${breakpoints.sm})  and (orientation: landscape) {
    min-height: 100vh;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 452px;
    background-image: url(${BackgroundMD});
    padding-bottom: 60px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 460px;
    background-image: url(${BackgroundLG});
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 533px;
    background-image: url(${BackgroundXL});
  }

  a {
    background-color: ${orange.base};
    outline: none;
    width: 261px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      max-width: 336px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2px;
      height: 28.25px;
      padding-top: 4px;
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: 358px;
      margin-bottom: 26px;
      height: 36.61px;
      padding-top: 7px;
    }

    @media (min-width: ${breakpoints.xl}) {
      margin-bottom: 0;
    }

    &:hover {
      background: ${orange.base};
      opacity: 0.9;
    }
  }

  .card-position {
    gap: 12px;
    @media (min-width: ${breakpoints.md}) {
      gap: 40px;
    }
  }
`

export const Card = styled.div`
  background-color: ${gray[500]};
  border-radius: 8px;
  padding: 16px;
  width: 102px;
  height: 96px;

  @media (min-width: ${breakpoints.md}) {
    width: 170px;
    height: 116px;
  }
`
