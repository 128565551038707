import React from 'react'

import homJSON from '../../../assets/data/inter-asset/home.json'

import * as S from './style'

const Hero = () => {
  return (
    <S.HeroSection className=''>
      <div className='container'>
        <div className='row'>
          <div className='col-10 col-lg-8 col-xl-6 mb-md-5'>
            <h1 className='f-sora fs-32 lh-40 fs-md-40 lh-md-50 fs-xl-48 lh-xl-60 text-white fw-600'>
              Consistência e resultados para você
            </h1>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-gray-400 fw-400 mb-4'>
              Somos uma empresa orientada pelas necessidade dos nossos clientes e trabalhamos de forma independente,{' '}
              sempre alinhados com seus interesses em busca dos melhores resultados no longo prazo.
            </p>
          </div>
          <div className='col col-lg-12 card-position d-flex flex-wrap'>
            <S.Card>
              <span className='fs-12 lh-15 fs-md-16 lh-md-20 fw-400 f-inter text-gray-650 d-block'>
                Mais de <span className='fs-20 lh-25 fs-md-32 lh-md-40 f-sora fw-600 text-gray-750 d-block my-1'>{homJSON.clients}</span>de clientes
              </span>
            </S.Card>
            <S.Card>
              <span className='fs-12 lh-15 fs-md-16 lh-md-20 fw-400 f-inter text-gray-650 d-block'>
                Mais de <span className='fs-20 lh-25 fs-md-32 lh-md-40 f-sora fw-600 text-gray-750 d-block my-1'>{homJSON.assetsUnderManagement}</span>em ativos
              </span>
            </S.Card>
            <S.Card>
              <span className='fs-12 lh-15 fs-md-16 lh-md-20 fw-400 f-inter text-gray-650 d-block'>
                Total de <span className='fs-20 lh-25 fs-md-32 lh-md-40 f-sora fw-600 text-gray-750 d-block my-1'>{homJSON.funds}</span>fundos
              </span>
            </S.Card>
          </div>
        </div>
      </div>
    </S.HeroSection>
  )
}

export default Hero
